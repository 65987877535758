/* eslint-disable no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
import { useStateUserInfo } from "@/hooks/user";
import { Box, Grid, useMediaQuery, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPeopleCounting } from "../../../services/DashboardService";
import { headerStyle } from "../css/peopleCounting";
import AnalyticalList from "./Component/AnalyticalList";
import ContentBoard from "./Component/ContentBoard";
import { getStoreData, getTimeData } from "./Component/GetTime";
import HeaderBoard from "./Component/HeaderBoard";
import LineCharts from "./Component/LineCharts";
import Aduit from "./Component/Aduit";

export default function PeopleCountingFun() {
  const { t } = useTranslation();
  const theme = useTheme();
  // 使用 useMediaQuery 钩子来获取当前屏幕的宽度
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // 在小屏幕下为 true
  const { enqueueSnackbar } = useSnackbar();
  const [startTimes, setStartTimes] = useState(""); //第一个时间组件选中的开始时间
  const [endTimes, setEndTimes] = useState(""); //第一个时间组件选中的结束时间
  const [startValueTime, setStartValueTime] = useState(""); //第二个时间组件选中的开始时间
  const [endValueTime, setEndValueTime] = useState(""); //第二个时间组件选中的结束时间

  const [totalVistord, setTotalVistord] = useState(0);
  const [highEst, setHighEst] = useState(0);
  const [highEstData, setHighEstData] = useState("");
  const [overAll, setOverAll] = useState(0);
  const [storeList, setStoreList] = useState([]); //所有门店数据
  const [storeName, setStoreName] = useState(""); //选中的门店名称
  const [barChartsData, setBarChartsData] = useState([]); // 柱状图列表数据
  const [previousPeriodsData, setPreviousPeriodsData] = useState([]);
  const [selectTime, setSelectTime] = useState(2); //选择的时间范围 类型 1 为7天 2 为5天
  const [conversionRateList, setConversionRateList] = useState([]);
  const [animationValue, setAnimationValue] = useState(true); //处理切换门店时禁止动画渲染
  const [aduitData, setAduitData] = useState([]);
  const [kidsRatio, setKidsRatio] = useState(0);
  const [adultRatio, setAdultRatio] = useState(0);

  const [countryInfo, setCountryInfo] = useState({});
  const [stateInfo, setStateInfo] = useState({});
  const [cityInfo, setCityInfo] = useState({});

  //主要判断是否初始化  初始化完成后，只能点击应用按钮才能调接口
  const [isInitialize, setIsInitialize] = useState(false);
  // 获取当前用户类型

  const [isShowAllStore, setIsShowAllStore] = useState(false);
  const userInfo = useStateUserInfo();
  const [clientType, setClientType] = useState(
    JSON.parse(localStorage.getItem("USER_INFO"))?.roleCode
  );

  const [clientId, setClientId] = useState(
    JSON.parse(localStorage.getItem("USER_INFO"))?.clientId
  );
  //设置当前时间和比较时间方法
  useEffect(() => {
    getTimeData(setStartTimes, setEndTimes, setStartValueTime, setEndValueTime);
    if (isInitialize === true) return;
    setIsInitialize(true);
  }, [isInitialize]);

  useEffect(() => {
    if (userInfo) {
      setClientType(userInfo.roleCode);
      setClientId(userInfo?.clientId);
    }
  }, [userInfo]);

  useEffect(() => {
    if (!clientType) {
      return;
    }
    if (
      clientType === "CLIENT_ADMIN" ||
      clientType === "CLIENT_USER" ||
      clientType === "SUPER_ADMIN" ||
      !clientId
    ) {
      setIsShowAllStore(true);
      setStoreName("All");
    } else {
      getStoreData(
        setStoreName,
        setStoreList,
        countryInfo,
        stateInfo,
        cityInfo
      );
    }
  }, [clientType]);

  useEffect(() => {
    if (
      startTimes !== "" &&
      endTimes !== "" &&
      startValueTime !== "" &&
      endValueTime !== "" &&
      isInitialize === true &&
      storeName !== ""
    ) {
      loadData();
    }
  }, [isInitialize]);

  const loadData = () => {
    let params = {
      type: selectTime,
      outletId: storeName === "All" ? "All" : storeName?.id,
      startDate: startTimes.replace(/\//g, "-"),
      endDate: endTimes.replace(/\//g, "-"),
      previousStartDate: startValueTime.replace(/\//g, "-"),
      previousEndDate: endValueTime.replace(/\//g, "-"),

      country: countryInfo?.id,
      state: stateInfo?.id,
      city: cityInfo?.id,
    };
    // setAnimationValue(true);
    getPeopleCounting(params).then((res) => {
      if (res?.data?.code === "LVLI0000" && res?.data?.data !== null) {
        let data = res?.data?.data;
        setTotalVistord(data?.totalVistord);
        setHighEst(data?.highestEntry);
        setHighEstData(data?.highestEntryDate);
        setOverAll(data?.overallChange);
        setBarChartsData(data?.entryVolume);
        setPreviousPeriodsData(data?.previousPeriod);
        setConversionRateList(data?.conversionRateList);
        setAduitData(data?.entryAdultAndKidsList);
        setKidsRatio(data?.kidsRatio);
        setAdultRatio(data?.adultRatio);
        // setAnimationValue(false);
      }
    });
  };

  return (
    <Grid
      sx={{
        padding: "0 0.5rem",
      }}
    >
      <Box fontSize={28}>{t("PCS01")}</Box>
      <Box sx={{ fontWeight: 700, color: "#000", fontSize: "24px" }}>
        {t("PCS02")}
      </Box>

      <HeaderBoard
        startTimes={startTimes}
        setStartTimes={setStartTimes}
        endTimes={endTimes}
        setEndTimes={setEndTimes}
        startValueTime={startValueTime}
        setStartValueTime={setStartValueTime}
        endValueTime={endValueTime}
        setEndValueTime={setEndValueTime}
        selectTime={selectTime}
        loadData={loadData}
        setSelectTime={setSelectTime}
        totalVistord={totalVistord}
        storeName={storeName}
        setStoreName={setStoreName}
        storeList={storeList}
        isShowAllStore={isShowAllStore}
        countryInfo={countryInfo}
        setCountryInfo={setCountryInfo}
        stateInfo={stateInfo}
        setStateInfo={setStateInfo}
        cityInfo={cityInfo}
        setCityInfo={setCityInfo}
      ></HeaderBoard>

      <Grid sx={headerStyle} mt={2}>
        <ContentBoard
          highEst={highEst}
          highEstData={highEstData}
          overAll={overAll}
          barChartsData={barChartsData}
          previousPeriodsData={previousPeriodsData}
          startTimes={startTimes}
          startValueTime={startValueTime}
          storeName={storeName}
          setAnimationValue={setAnimationValue}
          animationValue={animationValue}
        ></ContentBoard>
      </Grid>

      <Grid
        sx={{
          bgcolor: "#fff",
          border: "1px solid #eaebed",
          borderRadius: "0.2rem",
          padding: "0.5rem 0.6rem",
          mt: 2,
        }}
      >
        <Typography variant="h2" component="h2">
          {t("PCS18")}
        </Typography>

        <Grid
          flexGrow={1}
          display={"flex"}
          container
          justifyContent={"space-between"}
        >
          <Grid xl={6} sm={12} md={12} lg={10} item>
            <LineCharts
              previousPeriodsData={previousPeriodsData}
              conversionRateList={conversionRateList}
              storeName={storeName}
              setAnimationValue={setAnimationValue}
              animationValue={animationValue}
            ></LineCharts>
          </Grid>

          <Grid
            maxHeight={"400px"}
            overflowY={"auto"}
            item
            sm={0}
            md={0}
            lg={0}
            xl={5}
          >
            <AnalyticalList
              conversionRateList={conversionRateList}
            ></AnalyticalList>
          </Grid>
        </Grid>
      </Grid>

      <Grid sx={headerStyle} mt={2}>
        <Aduit
          startTimes={startTimes}
          startValueTime={startValueTime}
          aduitData={aduitData}
          kidsRatio={kidsRatio}
          adultRatio={adultRatio}
          previousPeriodsData={previousPeriodsData}
        ></Aduit>
      </Grid>
    </Grid>
  );
}
