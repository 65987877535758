import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Button,
  Tooltip,
  Grid,
  Radio,
  Dialog,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "@/components/DataTable";
import { useTranslation } from "react-i18next";
import CommonUtil from "@/util/CommonUtils";
import { getProductList } from "../../../services/ProductService";
import { useSnackbar } from "notistack";

const DialogChange = forwardRef((props, ref) => {
  const {
    open,
    setOpen,
    selectValue,
    deviceOutLetId,
    clientID,
    productListRef,
  } = props;

  const { t } = useTranslation();
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectRow, setSelectRow] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  //模糊搜索的值
  const [inputValue, setInputValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const defaultFilters = {
    pageNumber: 1,
    pageSize: 5,
  };

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 5,
  });

  /**
   * @method getloadData
   * 加载表格数据
   */

  // 使用 useImperativeHandle 将方法暴露给父组件
  useImperativeHandle(ref, () => ({
    getloadData: getloadData,
  }));

  const getloadData = () => {
    if (isSearching) {
      return; // 如果正在搜索，则不执行请求数据的操作
    }
    let params = {
      outletId: deviceOutLetId,
      clientId: clientID,
      pageNumber: filters.pageNumber,
      pageSize: filters.pageSize,
    };
    getProductList(params).then((res) => {
      setRecords(res?.data?.data?.products);
      setTotalRecords(res?.data?.data?.totalCount);
    });
  };
  useEffect(() => {
    getloadData();
    filiterData();
  }, [filters, isSearching]);

  useEffect(() => {
    if (inputValue === "") {
      filiterData();
    }
  }, [inputValue]);
  /**
   * @method handleAgreeButtonClick
   * 点击确定插入数据
   */
  const handleAgreeButtonClick = () => {
    if (selectValue) {
      selectValue(selectRow);
    }
    setOpen(false);
    setInputValue("");
  };

  /**
   * @method handleChange
   * 根据id 保存选中行id
   */
  const [selectedValue, setSelectedValue] = React.useState("");
  const handleChange = (event, rowId) => {
    setSelectedValue(rowId);
  };
  /**
   * @method renderRadioColumn
   * 单选框 渲染方法
   */
  const renderRadioColumn = (params) => {
    const rowId = getRowId(params.row);
    if (rowId && rowId.length > 0) {
      let filterResult = records.filter((item) => {
        if (item.id === selectedValue) {
          return true;
        } else {
          return false;
        }
      });

      if (filterResult && filterResult.length > 0) {
        setSelectRow(filterResult[0]);
      }
    } else {
      setSelectRow(null);
    }
    return (
      <Radio
        checked={selectedValue === rowId}
        onChange={(event) => handleChange(event, rowId)}
        value={rowId}
        name="radio-buttons"
        inputProps={{ "aria-label": rowId }}
      />
    );
  };

  const getRowId = (data) => data.id;
  const columns = [
    {
      field: "radio",
      headerName: "",
      width: 50,
      sortable: false,
      renderCell: renderRadioColumn,
    },
    {
      field: "productName",
      headerName: `${t("LVLDV0070")}`,
      flex: 1,
      sortable: false,
      // editable: true,
      renderCell: (e) => (
        <Tooltip
          title={
            e.row.productDO ? e.row.productDO.productName : e.row.productName
          }
          arrow
          placement="bottom"
        >
          <span>
            {CommonUtil.formatLongText(
              e.row.productDO ? e.row.productDO.productName : e.row.productName
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "barCode",
      headerName: `${t("LVLDV0071")}`,
      flex: 1,
      sortable: false,
      // editable: true,
      renderCell: (e) => (
        <Tooltip
          title={e.row.productDO ? e.row.productDO.barCode : e.row.barCode}
          arrow
          placement="bottom"
        >
          <span>
            {CommonUtil.formatLongText(
              e.row.productDO ? e.row.productDO.barCode : e.row.barCode
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "categore level1",
      headerName: `${t("LVLDV0072")}`,
      flex: 1,
      sortable: false,
      // editable: true,
      renderCell: (e) => (
        <Tooltip
          title={
            e.row.productDO
              ? e.row.productDO.categoryLevel1
              : e.row.categoryLevel1
          }
          arrow
          placement="bottom"
        >
          <span>
            {CommonUtil.formatLongText(
              e.row.productDO
                ? e.row.productDO.categoryLevel1
                : e.row.categoryLevel1
            )}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "categore level2",
      headerName: `${t("LVLDV0073")}`,
      flex: 1,
      sortable: false,
      // editable: true,
      renderCell: (e) => (
        <Tooltip
          title={
            e.row.productDO
              ? e.row.productDO.categoryLevel2
              : e.row.categoryLevel2
          }
          arrow
          placement="bottom"
        >
          <span>
            {CommonUtil.formatLongText(
              e.row.productDO
                ? e.row.productDO.categoryLevel2
                : e.row.categoryLevel2
            )}
          </span>
        </Tooltip>
      ),
    },
  ];

  /**
   * @method handlePageChange
   * 切换当前页
   */
  const handlePageChange = (e) => {
    setFilters({
      ...filters,
      pageNumber: e + 1,
    });
  };

  /**
   * @method handlePageSize
   * 切换每页数量
   */
  const handlePageSize = (e) => {
    setFilters({
      pageNumber: defaultFilters.pageNumber,
      pageSize: e,
    });
  };

  /**
   * @method 关闭弹窗
   */
  const handlerCancel = () => {
    setOpen(false);
    setInputValue("");
  };

  const handleSearch = () => {
    if (inputValue === "") {
      enqueueSnackbar(t("PCS88"), {
        variant: "info",
      });
      getloadData();
      return;
    }
    setFilters({ ...filters, pageNumber: 1 }); // 将pageNumber设置为1
    setIsSearching(true);
    filiterData();
  };

  const filiterData = () => {
    const params = {
      productName: inputValue,
      outletId: deviceOutLetId,
      clientId: clientID,
      pageNumber: filters.pageNumber,
      pageSize: filters.pageSize,
    };
    getProductList(params).then((res) => {
      setRecords(res?.data?.data?.products);
      setTotalRecords(res?.data?.data?.totalCount);
    });
  };

  return (
    <Dialog open={open}>
      <DialogContent>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid>
            <TextField
              size="small"
              label={t("LVLDV0063")}
              variant="outlined"
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleSearch(inputValue)}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: "10px",
            height: "400px",
            width: "700px",
            overflowY: "auto",
          }}
        >
          <DataTable
            height="auto"
            rows={records || []}
            columns={columns}
            rowCount={totalRecords}
            getRowId={getRowId}
            pageSizeOptions={[5, 10, 20, 30, 50]}
            page={filters.pageNumber - 1}
            disableColumnMenu
            onSelection={() => console.log("11111111111")}
            totalRecords={totalRecords}
            rowsPerPage={filters.pageSize}
            onPageChange={(pn) => handlePageChange(pn)}
            onPageSizeChange={(ps) => handlePageSize(ps)}
            checkboxSelection={false}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlerCancel}>{t("LVLDB0012")}</Button>
        <Button onClick={handleAgreeButtonClick} autoFocus variant="contained">
          {t("LVLDB0011")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default DialogChange;
