/* eslint-disable no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Button } from "@mui/material";
import CustomCards from "./CustomCards";
import DialogTimee from "./DIalogTime";
import CompareTime from "./CompareTime";
import { useTranslation } from "react-i18next";
import { parseNumber } from "@/util/parseNumber";
import { exportExCelData } from "@/services/DashboardService";
import SelectOutlet from "../../Component/SelectOutlet";
import LocationDialog from "./locationDialog";
import CommonUtil from "@/util/CommonUtils";
import LocationCard from "./LocationCard";
import {
  selectCity,
  selectCityZone,
  selectCountry,
  selectState,
} from "@/services/Location";
import { format } from "date-fns";

function HeardBoard(props) {
  const {
    startTimes,
    setStartTimes,
    endTimes,
    setEndTimes,
    startValueTime,
    setStartValueTime,
    endValueTime,
    setEndValueTime,
    selectTime,
    setSelectTime,
    loadData,
    storeName,
    setStoreName,
    storeList,
    totalVistord,
    isShowAllStore,
    countryInfo,
    setCountryInfo,
    stateInfo,
    setStateInfo,
    cityInfo,
    setCityInfo,
  } = props;
  const { t } = useTranslation();
  const totalValue = parseNumber(totalVistord);
  const [open, setOpen] = React.useState(false); //打开第一个时间弹窗
  const [open1, setOpen1] = useState(false);
  const [storeOpen, setStoreOpen] = useState(false); //所有门店下拉弹窗

  const [openCountry, setOpenCountry] = useState(false);
  const [openState, setOpenState] = useState(false);
  const [openCity, setOpenCity] = useState(false);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    selectCountry().then((res) => {
      if (res?.data?.code === "LVLI0000") {
        setCountries(res?.data?.data);
      } else {
        setCountries([]);
      }
    });
  }, []);

  useEffect(() => {
    setStateInfo({});
    setCityInfo({});
    setStoreName("All");
    if (!CommonUtil.isEmpty(countryInfo)) {
      selectState({
        countryId: countryInfo?.id,
      }).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setStates(res?.data?.data);
        }
      });
    } else {
      setStates([]);
    }
  }, [countryInfo]);

  useEffect(() => {
    setCityInfo({});
    setStoreName("All");
    if (!CommonUtil.isEmpty(stateInfo)) {
      selectCity({
        stateId: stateInfo?.id,
      }).then((res) => {
        if (res?.data?.code === "LVLI0000") {
          setCities(res?.data?.data);
        }
      });
    } else {
      setCities([]);
    }
  }, [stateInfo]);

  useEffect(() => {
    setStoreName("All");
  }, [cityInfo]);

  //导出数据方法
  const handlerExport = () => {
    let params = {
      type: selectTime, //按月导出 / 按天导出
      outletId: storeName === "All" ? "All" : storeName?.id,
      startDate: startTimes.replace(/\//g, "-"),
      endDate: endTimes.replace(/\//g, "-"),
      previousStartDate: startValueTime.replace(/\//g, "-"),
      previousEndDate: endValueTime.replace(/\//g, "-"),
      country: countryInfo?.id,
      state: stateInfo?.id,
      city: cityInfo?.id,
      countryName: countryInfo?.name,
      stateName: stateInfo?.name,
      cityName: cityInfo?.name,
    };

    exportExCelData(params).then((res) => {
      const link = document.createElement("a");
      let blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      });
      link.style.display = "none";
      // link.download = res.headers[""]; //下载后文件名
      link.download =
        "PeopCounting_Data_" + format(new Date(), "yyyy_MM_dd_HH_mm_ss"); //下载的文件名
      link.href = URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <React.Fragment>
      <Grid display={"flex"} justifyContent={"space-between"}>
        <Grid>
          <Grid display={"flex"}>
            <LocationCard
              title={t("LVLGF0012")}
              onClick={() => setOpenCountry(true)}
              open={openCountry}
              locationName={countryInfo?.name}
              placeholder={t("subscription.please_select_country")}
            ></LocationCard>

            <LocationCard
              title={t("LVLGF0002")}
              onClick={() => setOpenState(true)}
              open={openState}
              locationName={stateInfo?.name}
              placeholder={t("subscription.please_select_province")}
            ></LocationCard>

            <LocationCard
              title={t("LVLGF0003")}
              onClick={() => setOpenCity(true)}
              open={openCity}
              locationName={cityInfo?.name}
              placeholder={t("subscription.please_select_city")}
            ></LocationCard>
          </Grid>

          <Grid display={"flex"}>
            <CustomCards
              title={t("PCS03")}
              startTimes={startTimes}
              endTimes={endTimes}
              startName={t("PCS05")}
              endName={t("PCS06")}
              onClick={() => setOpen(true)}
              open={open1}
            ></CustomCards>

            <CustomCards
              title={t("PCS136")}
              startTimes={startValueTime}
              endTimes={endValueTime}
              startName={t("PCS05")}
              endName={t("PCS06")}
              onClick={() => setOpen1(true)}
              open={open1}
            ></CustomCards>

            <CustomCards
              title={t("PCS08")}
              store={true}
              storeName={storeName}
              isShowAllStore={isShowAllStore}
              onClick={() => setStoreOpen(true)}
              open={storeOpen}
            ></CustomCards>

            <Button
              variant="contained"
              onClick={() => loadData()}
              sx={{
                height: "100%",
                borderRadius: "15px",
                fontWeight: 700,
                fontSize: "0.4rem",
                padding: "0.3rem 0.5rem",
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: t("PCS10") }}></span>
            </Button>
          </Grid>
        </Grid>

        <Grid display={"flex"} justifyContent={"space-between"}>
          <Grid>
            <Grid display={"flex"}>
              <Box
                mr={2}
                fontWeight={700}
                sx={{ fontSize: "0.4rem", color: "#878787" }}
              >
                <span dangerouslySetInnerHTML={{ __html: t("PCS11") }} />
              </Box>
              <Box
                sx={{
                  background: "#fff",
                  padding: "0.9vh 1vw",
                  color: "#fff",
                  borderRadius: "15px",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                <span
                  style={{
                    background: `linear-gradient(to right, #71b652, #3599d7)`,
                    WebkitBackgroundClip: "text",
                    fontWeight: 700,
                    WebkitTextFillColor: "transparent",
                    fontSize: "0.9rem",
                  }}
                >
                  {totalValue}
                </span>
              </Box>
            </Grid>
            <Button
              onClick={handlerExport}
              sx={{
                width: "100%",
                height: "40%",
                background: "#1487cb",
                color: "#FFF",
                fontSize: "0.5rem",
                fontWeight: 700,
                borderRadius: "15px",
                marginTop: "10px",
              }}
            >
              {t("MATADATA01")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* 第一个时间选择组件 */}
      <DialogTimee
        open={open}
        setOpen={setOpen}
        setStartTimes={setStartTimes}
        endTimes={endTimes}
        setEndTimes={setEndTimes}
        setStartValueTime={setStartValueTime}
        setEndValueTime={setEndValueTime}
        selectTime={selectTime}
        setSelectTime={setSelectTime}
      ></DialogTimee>

      {/* 第二个时间范围选择组件 */}
      <CompareTime
        open1={open1}
        endTimes={endTimes}
        setOpen1={setOpen1}
        startTimes={startTimes}
        selectTime={selectTime}
        setStartValueTime={setStartValueTime}
        setEndValueTime={setEndValueTime}
      ></CompareTime>

      {storeName && (
        <SelectOutlet
          storeOpen={storeOpen}
          setStoreOpen={setStoreOpen}
          setStoreName={setStoreName}
          storeName={storeName}
          storeList={storeList}
          isShowAllStore={isShowAllStore}
          countryInfo={countryInfo}
          stateInfo={stateInfo}
          cityInfo={cityInfo}
        ></SelectOutlet>
      )}

      <LocationDialog
        open={openCountry}
        setOpen={setOpenCountry}
        options={countries}
        label={t("Country")}
        selectInfo={countryInfo}
        setSelectInfo={setCountryInfo}
      ></LocationDialog>

      <LocationDialog
        open={openState}
        setOpen={setOpenState}
        options={states}
        label={t("State/Provience")}
        selectInfo={stateInfo}
        setSelectInfo={setStateInfo}
      ></LocationDialog>

      <LocationDialog
        open={openCity}
        setOpen={setOpenCity}
        options={cities}
        label={t("City")}
        selectInfo={cityInfo}
        setSelectInfo={setCityInfo}
      ></LocationDialog>
    </React.Fragment>
  );
}
export default HeardBoard;
