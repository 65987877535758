import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Autocomplete,
  TextField,
  Typography,
  DialogTitle,
  Paper,
} from "@mui/material";

import { useTranslation } from "react-i18next";

const LocationDialog = forwardRef((props, ref) => {
  const { open, setOpen, options, selectInfo, setSelectInfo, placeholder } =
    props;
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    setDataList(options);
  }, [options]);
  return (
    <Dialog open={open} onClose={() => setOpen(false)} sx={{ mb: 40 }}>
      <DialogTitle
        sx={{ m: 0, p: 2, fontFamily: "Myriad Pro" }}
        fontSize={26}
        fontWeight={700}
      >
        <Typography variant="h6">
          {t("subscription.please_select_location")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          options={dataList || []}
          value={selectInfo || ""}
          sx={{ width: 600 }}
          placeholder={placeholder}
          getOptionLabel={(option) => (option.name ? option.name : "")}
          onChange={(e, v) => {
            setSelectInfo(v);
            setOpen(false);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder={placeholder} />
          )}
        />
      </DialogContent>
    </Dialog>
  );
});

export default LocationDialog;
