import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
function AnalyticalList({ conversionRateList }) {
  const { t } = useTranslation();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontSize: 16,
      lineHeight: "20px",
      border: "none",
      // borderRadius: "30px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
    },

    "&:nth-of-type(even)": {
      backgroundColor: "#f5f5f5",
    },
  }));

  const columns = [
    {
      field: "period",
      headerName: t("PCS21"),
    },
    {
      field: "passby",
      headerName: t("PCS139"),
    },
    {
      field: "enter",
      headerName: t("PCS140"),
    },
    {
      field: "leave",
      headerName: t("PCS141"),
    },

    {
      field: "prev",
      headerName: t("PCS142"),
    },
    {
      field: "change",
      headerName: t("PCS143"),
    },
  ];

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: "400px",
        overflow: "auto",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns?.map((header, index) => {
              return (
                <StyledTableCell align="center" key={header.field}>
                  {header.headerName}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody component={Paper}>
          {conversionRateList?.map((row, index) => {
            const change = row.change;
            const changeText = `${change > 0 ? "+" : ""}${change}%`;
            const changeColor = change < 0 ? "red" : "green";
            return (
              <TableRow key={index}>
                <StyledTableCell align="center">
                  {row.event_date.replace(/-/g, "/")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.passByNum}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  {row?.inNum}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  {row?.outNum}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  {row.c_conversion_rate}%
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  align="center"
                  sx={{ color: changeColor }}
                >
                  {row.p_conversion_rate}%
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AnalyticalList;
