import React, { useEffect, useState } from "react";
import { Grid, styled } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as CalenderIcon } from "@/assets/images/calendar-icon.svg";
import CustomInput from "@/components/CustomInput";
import zhLocale from "date-fns/locale/zh-CN";
import enLocale from "date-fns/locale/en-US";
import i18n from "i18next";
import AppLanguage from "@/lang/AppLanguages";
const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
  border: "1px solid green",
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: "green",
    color: "#FFFF",
  },
}));

const CustomIconRight = styled(ChevronRightIcon)(() => ({
  border: "1px solid green",
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: "green",
    color: "#FFFF",
  },
}));

export default function CustomDatePicker(props) {
  const [date, setDate] = useState(null);
  const [local, setLocal] = useState(null);
  useEffect(() => {
    setDate(props.date);
  }, [props.date]);

  useEffect(() => {
    let language = AppLanguage.getDefaultLanguage();
    language = language === null ? "en" : language;
    setLocal(language);
    i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    props.SelectedDate(firstDayOfMonth);
  }, []);

  const join = (t, a, s) => {
    try {
      function format(m) {
        let f = new Intl.DateTimeFormat("en", m);
        return f.format(t);
      }
      return a.map(format).join(s);
    } catch (error) {}
  };

  const handleDate = (e) => {
    let formet = [{ day: "numeric" }, { month: "short" }, { year: "numeric" }];
    // let formet = [{ year: "numeric" }, { month: "short" }, { day: "numeric" }];
    let formetChange = join(e, formet, " ");
    setDate(e);
    props.SelectedDate(e);
  };
  const views = props.type == 1 ? ["year", "month", "day"] : ["year", "month"];

  return (
    <Grid style={{ width: "100%", height: "100%" }}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={local == "en" ? enLocale : zhLocale}
      >
        <DesktopDatePicker
          inputFormat={"dd-MM-yyyy"}
          disableFuture={props.disableFuture}
          disabled={props.disabled}
          placement="bottom-right"
          maxDate={props.maxDate}
          minDate={props.minDate}
          components={{
            OpenPickerIcon: CalenderIcon,
            LeftArrowIcon: CustomIconLeft,
            RightArrowIcon: CustomIconRight,
          }}
          dateRangeIcon={<CalenderIcon />}
          label={props.label}
          views={views}
          value={date}
          sx={{
            p: 1,
          }}
          onChange={(selectedDate) => {
            const newDate = new Date(
              selectedDate.getFullYear(),
              selectedDate.getMonth(),
              1
            );
            if (props.type == 1) {
              handleDate(selectedDate);
            } else {
              handleDate(newDate);
            }
          }}
          renderInput={(params) => (
            <CustomInput
              fullWidth={true}
              handleChange={(e) => console.log()}
              size={props.size ? props.size : " "}
              value={date}
              style={{ width: "100%" }}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: props.placeholder,
              }}
            />
          )}
        />
      </LocalizationProvider>
    </Grid>
  );
}
